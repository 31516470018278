/* eslint-disable react/prop-types */
// @ts-check

import { Badge, Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FaCircle, FaLock } from 'react-icons/fa';
import { FaUserGroup } from 'react-icons/fa6';
import { StudioGoLiveCountdown } from './GoLive/Countdown';
import { useStudioStatus } from './Status/Context';
import { useStudio } from './Context';

import './StudioHeaderStatus.scss';

export const StudioFullscreenHeaderStatusBadge = () => {
	const { t } = useTranslation();

	const {
		isLiveAlmostTerminated: isLiveStopped,
		isLiveAboutToStart,
		isLiveRecordingStarted,
		isLiveActive,
		isStudioRunning,
		isStudioPending: isStudioStarting,
	} = useStudioStatus();

	const { numberOfPeopleInStudio } = useStudio();

	if (isLiveAboutToStart && !isLiveRecordingStarted) return (<StudioGoLiveCountdown />);

	if (isLiveRecordingStarted || isLiveActive) {
		return (
			<div className="HeaderStudioStatus d-flex align-items-center">
				<div className="w-100 d-flex h-100">
					<div className="HeaderStudioStatus_status w-50 bg-primary h-100 d-flex justify-content-center text-black align-items-center gap-1">
						<FaCircle size={12} /><span>{t('Studio.StatusManager.onAir')}</span>
					</div>
					<div className="HeaderStudioStatus_count w-50 bg-white d-flex align-items-center justify-content-end pr-2 gap-1 text-black">
						<span>{numberOfPeopleInStudio}</span>
						<FaUserGroup />
					</div>
				</div>
			</div>
		);
	}

	if (isLiveStopped && isStudioRunning) {
		return (
			<Badge
				color="secondary"
				title={t('Studio.StatusManager.liveEnded')}
			>
				<FaLock className="me-2" />{t('Studio.StatusManager.liveEnded')}
			</Badge>
		);
	}

	if (isStudioRunning) {
		return (
			<div className="HeaderStudioStatus d-flex align-items-center">
				<div className="w-100 d-flex h-100">
					<div className="HeaderStudioStatus_status w-50 bg-primary d-flex justify-content-center align-items-center text-black gap-1">
						<FaLock size={12} />
						<span className="font-size-sm pt-1">{t('Studio.StatusManager.private')}</span>
					</div>
					<div className="HeaderStudioStatus_count w-50 bg-white d-flex align-items-center justify-content-end pr-2 gap-1 text-black">
						<span>{numberOfPeopleInStudio}</span>
						<FaUserGroup />
					</div>
				</div>
			</div>
		);
	}

	if (isStudioStarting) {
		return (
			<Badge
				color="secondary"
				title={t('Studio.StatusManager.studioIsStarting')}
				className="d-flex flex-row align-items-center"
			>
				<Spinner className="me-2 text-white" size="sm" />{t('Studio.StatusManager.studioIsStarting')}
			</Badge>
		);
	}

	return null;
};
