import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import './Balance.scss';

const smoothIncrementTime = [
	75,
	150,
	225,
	300,
	375,
	500,
	675,
	825,
	1000,
	1250,
];

export const PointsBalance = ({
	balance,
	name,
}) => {
	const [currentBalance, setCurrentBalance] = useState(balance);
	const balanceRef = useRef(balance);
	const [balanceCss, setBalanceCss] = useState('');

	useEffect(() => {
		if (balance === balanceRef.current) return undefined;

		const delta = balance - balanceRef.current;
		const increment = delta / smoothIncrementTime.length;
		const cssIncrementDirection = delta >= 0 ? 'up' : 'down';
		balanceRef.current = balance;

		setBalanceCss(`PointsBalance__increase-${cssIncrementDirection}`);
		const timers = [];
		for (let i = 0; i < smoothIncrementTime.length; i += 1) {
			timers.push(setTimeout(() => {
				setCurrentBalance((prevValue) => prevValue + increment);
				if (i === smoothIncrementTime.length - 1) {
					timers.push(setTimeout(() => setBalanceCss(''), 500));
				}
			}, smoothIncrementTime[i]));
		}

		return () => {
			timers.forEach((timer) => clearTimeout(timer));
			setCurrentBalance(balance);
			setBalanceCss('');
		};
	}, [balance]);

	return (
		<span
			className={`PointsBalance ${balanceCss}`}
			translate="no"
		>
			{Math.floor(currentBalance)} {name}
		</span>
	);
};

PointsBalance.propTypes = {
	balance: PropTypes.number,
	name: PropTypes.string.isRequired,
};

PointsBalance.defaultProps = {
	balance: 0,
};
