/* eslint-disable react/prop-types */
// @ts-check

import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	Button, ButtonDropdown, ButtonGroup, DropdownMenu, DropdownToggle,
	Nav, NavItem, NavLink,
} from 'reactstrap';
import { FaEllipsisV, FaPowerOff } from 'react-icons/fa';
import { BsBroadcast } from 'react-icons/bs';

import { ButtonPillOutline } from '../Button';
import { CompleteProfileModal } from '../Profile/CompleteModal';
import { useProfile } from '../Profile/ProfileContext';
import { StudioModal } from './Context';
import { useStudio } from './Provider';
import { StudioScheduleModal, useStudioSchedule } from '../StudioSchedule/Context';
import { useCurrentStudio } from './useCurrentStudio';
import { StudioState } from '../StudioSchedule/helper';
import { StudioGoLiveStopModal } from './GoLive/StopModal';
import { StudioGoLiveModal } from './GoLive/Modal';
import { useStudioStatus } from './Status/Context';

import './Button.scss';

/**
 * @typedef {{
 * 	isFullscreen?: boolean,
 * }} StudioButtonActiveProps
 */
export const StudioButtonActive = (
/** @type {StudioButtonActiveProps} */
	{ isFullscreen = false },
) => {
	const { t } = useTranslation();
	const { setModalOpen } = useStudio();

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [isGoLiveModalOpen, setIsGoLiveModalOpen] = useState(false);
	const [isStopLiveModalOpen, setIsStopLiveModalOpen] = useState(false);
	const { openModal } = useStudioSchedule();

	const {
		isLiveAlmostTerminated: isLiveStopped,
		isLiveRecordingStarted,
		isLiveActive,
		isLiveAboutToStart,
		isLivePlanned,
		isStudioPublic,
		isStudioRunning,
	} = useStudioStatus();

	const showEndButton = !isStudioPublic
		|| isLiveStopped
		|| (isLiveAboutToStart && !isLiveRecordingStarted);
	const showGoLiveButton = isLivePlanned && !isLiveAboutToStart;
	const showStopLiveButton = isLiveActive || isLiveRecordingStarted;

	const handleClick = useCallback(() => {
		if (showEndButton) return setModalOpen(StudioModal.TERMINATE);
		if (showGoLiveButton) return setIsGoLiveModalOpen(true);
		if (showStopLiveButton) return setIsStopLiveModalOpen(true);
		if (isStudioRunning) return setModalOpen(StudioModal.TERMINATE);
		return null;
	}, [setModalOpen, showEndButton, showGoLiveButton, showStopLiveButton, isStudioRunning]);

	const goLiveModalInitialRender = useRef(false);

	useEffect(() => {
		if (showGoLiveButton && !goLiveModalInitialRender.current) {
			goLiveModalInitialRender.current = true;
			setIsGoLiveModalOpen(true);
		}
	}, [showGoLiveButton]);

	const isGoPublicLiveModalOpen = isLivePlanned && !isLiveAboutToStart && isGoLiveModalOpen;

	const isButtonDisabled = !isStudioRunning || (isLiveAboutToStart && !isLiveRecordingStarted);

	return (
		<>
			<ButtonGroup>
				{isFullscreen ? (
					<Button
						color="danger"
						className="rounded-circle d-flex align-items-center justify-content-center p-0 ml-2"
						style={{ width: '35px', height: '35px' }}
						disabled={isButtonDisabled}
						onClick={handleClick}
						size="sm"
					>
						{(showEndButton || showStopLiveButton || (!showGoLiveButton && isStudioRunning)) && (
							<FaPowerOff size={18} />
						)}
						{showGoLiveButton && (
							<BsBroadcast size={18} />
						)}
					</Button>
				) : (
					<Button
						color="danger"
						className="flex-shrink-0 font-weight-bold pl-3"
						disabled={isButtonDisabled}
						onClick={handleClick}
						size="sm"
					>
						{showEndButton && (
							<>
								<FaPowerOff className="mr-3" size={20} />
								<span>{t('Studio.Button.end')}</span>
							</>
						)}
						{showGoLiveButton && (
							<>
								<BsBroadcast className="mr-sm-3" size={20} />
								<span className="d-none d-sm-block">{t('Studio.Button.goLive')}</span>
							</>
						)}
						{showStopLiveButton && <span className="d-none d-sm-block">{t('Studio.Button.stopLive')}</span>}
					</Button>
				)}
				<ButtonDropdown
					isOpen={dropdownOpen}
					toggle={() => setDropdownOpen(!dropdownOpen)}
				>
					{isFullscreen ? (
						<DropdownToggle className="shadow-none border-0 p-0" style={{ background: 'none' }}>
							<FaEllipsisV size={20} className="text-white" />
						</DropdownToggle>
					) : (
						<DropdownToggle
							caret
							className="px-1 StudioButtonDropdown"
							color="neutral-danger"
						/>
					)}
					<DropdownMenu end className="overflow-hidden p-2 bg-light border border-dark">
						<Nav pills className="nav-neutral-secondary flex-column font-weight-normal">
							<NavItem>
								<NavLink className="font-weight-normal" onClick={() => openModal(StudioScheduleModal.MANAGE, null, null, { defaultTab: StudioState.ONGOING })}>
									{t('Studio.Button.manage')}
								</NavLink>
							</NavItem>
							{!showEndButton && (
								<NavItem>
									<NavLink className="font-weight-normal" onClick={() => setModalOpen(StudioModal.TERMINATE)}>
										{t('Studio.Button.end')}
									</NavLink>
								</NavItem>
							)}
						</Nav>
					</DropdownMenu>
				</ButtonDropdown>
			</ButtonGroup>
			{isGoPublicLiveModalOpen && (
				<StudioGoLiveModal
					isOpen={isGoPublicLiveModalOpen}
					toggle={() => setIsGoLiveModalOpen(false)}
				/>
			)}
			{isStopLiveModalOpen && (
				<StudioGoLiveStopModal
					isOpen={isStopLiveModalOpen}
					toggle={() => setIsStopLiveModalOpen(false)}
				/>
			)}
		</>
	);
};

/**
 * @typedef {{
 * 	isFullscreen?: boolean,
 * }} StudioButtonLeaveProps
 */

export const StudioButtonLeave = (
/** @type {StudioButtonLeaveProps} */
	{ isFullscreen },
) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const {
		isCurrentStudioStarted,
	} = useCurrentStudio();

	const handleLeaveStudio = useCallback(() => {
		navigate('/');
	}, [navigate]);

	return isCurrentStudioStarted && (isFullscreen ? (
		<Button
			color="danger"
			className="rounded-circle d-flex align-items-center justify-content-center p-0 flex-shrink-0 ml-2"
			style={{ width: '35px', height: '35px' }}
			onClick={handleLeaveStudio}
			size="sm"
		>
			<FaPowerOff size={18} />
		</Button>
	)
		: (
			<ButtonPillOutline
				color="secondary"
				className="flex-shrink-0 shadow-none w-100"
				onClick={handleLeaveStudio}
				size="sm"
			>
				{t('Studio.Button.exit')}
			</ButtonPillOutline>
		)
	);
};

export const StudioButton = () => {
	const [showCompleteProfileModal, setShowCompleteProfileModal] = useState(false);
	const { profile } = useProfile();

	return (
		<div className="d-flex align-items-center">
			{!profile?.completedRewardClaimed && (
				<CompleteProfileModal
					isOpen={showCompleteProfileModal}
					close={() => setShowCompleteProfileModal(false)}
				/>
			)}
		</div>
	);
};
